// DO NOT EDIT THIS FILE !!!! GENERATED DURING BUILD TYPE : Ansible
export const DOL_VERSION='[production]6.0-rc-10288(6602c1d102)-C31/05/2024-16:22:39-B31/05/2024-17:30:10' ;
export const DOL_VERSION_JSON = {
  dolVersion: "[production]6.0-rc-10288(6602c1d102)-C31/05/2024-16:22:39-B31/05/2024-17:30:10",
  branch: "production",
  latestTag: "6.0-rc",
  revCount: "10288",
  shortHash: "6602c1d102",
  longHash: "6602c1d10268182d7031b16ede568b64da82ad9c",
  dateCommit: "31/05/2024-16:22:39",
  dateBuild: "31/05/2024-17:30:10",
  buildType: "Ansible",
  } ;
